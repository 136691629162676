
export default {
    props: {
        filters: {
            type: Boolean,
            default: true,
        },

        alt: {
            type: Boolean,
            default: false,
        },

        carousel: {
            type: Boolean,
            default: false,
        },

        mobileCarousel: {
            type: Boolean,
            default: false,
        },

        cols: {
            type: Number,
            default: 3,
        },

        noLoadMore: Boolean,
    },

    async fetch() {
        await this.$axios
            .$get(`/api/v1/content/list`, {
                params: {
                    taxonomy_categories: this.selectedCategory,
                    page: this.page,
                    content_type: 'videos',
                    per_page: 32,
                    tags: this.$route.query.tags || undefined,
                },
            })
            .then((res) => {
                this.data = this.page === 1 ? res.data : [...this.data, ...res.data]
                this.meta = res.meta
            })
    },

    data() {
        return {
            data: [],
            meta: {},
            page: 1,
            categories: [],
            mobile: undefined,
        }
    },

    computed: {
        selectedCategory: {
            get() {
                return this.$route.query.taxonomy_categories || undefined
            },

            set(val) {
                this.$router.push({ name: this.$route.name, params: this.$route.params, query: { taxonomy_categories: val } })
            },
        },

        mobileOnlyCarousel() {
            return this.mobileCarousel && this.mobile
        },
    },

    watch: {
        '$route.query': {
            handler() {
                this.$fetch()
            },
        },

        page() {
            this.$fetch()
        },
    },

    mounted() {
        this.mobile = process.browser ? window.innerWidth < 992 : null
    },
}
